import {
  useLoginMutation,
  useLogoutMutation,
} from '@features/Auth/services/auth';
import {
  authActions,
  isAuthenticated as isAuthenticatedSelector,
  setIntended,
} from '@features/Auth/store';
import {
  logOut as logOutAction,
  logOutCheckout,
} from '@features/Auth/store/actions';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { useGetMeQuery } from '@shared/services/users';
import { useAppDispatch, useAppSelector } from '@shared/store';
import { LoginRequest, UserMe } from '@shared/types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const LOGIN_ROUTE = '/login';
const LOGIN_ROUTE_NEW = '/login2';
const HOME_ROUTE = '/my-account/overview';

let authInterval: undefined | NodeJS.Timeout;

interface UseAuthReturnValue {
  isError: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  handleIsLoggedIn: () => void;
  logOut: () => Promise<void>;
  logOutFromCheckout: () => void;
  login: (
    credentials: LoginRequest,
    callback: null | Function,
    noRedirect?: boolean
  ) => void;
  logOutDeletedUser: () => void;
  user: UserMe | any;
  switchUserCheckout: () => void;
  refetch: () => QueryActionCreatorResult<
    QueryDefinition<
      null,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      'User' | 'Membership' | 'Wash' | 'Invoice',
      UserMe,
      'userApi'
    >
  >;
}

const useAuth = (
  authRestrictedPage = true,
  isNewLogin?: boolean
): UseAuthReturnValue => {
  const router = useRouter();
  const { intended, token } = useAppSelector((state) => state.auth);
  const [login, { isLoading: isLoggingIn, isError }] = useLoginMutation();
  const [logoutRequest] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const {
    data: user,
    refetch,
    isLoading: isLoadingUser,
  } = useGetMeQuery(null, {
    skip: !isAuthenticated,
  });

  /**
   * Handles when user is logged in
   */

  const handleIsLoggedIn = () => {
    if (intended) {
      // push and clear intended path
      router.push(intended).then(() => dispatch(setIntended(null)));
    } else {
      router.push(HOME_ROUTE);
    }
  };

  /**
   * Handles when user wants to log out and switch to another user during checkout
   */
  const switchUserCheckout = () => {
    dispatch(authActions.logOut());
  };

  /**
   * Log out
   */
  const logOut = () =>
    logoutRequest()
      .unwrap()
      .then(() => dispatch(logOutAction()));

  const logOutFromCheckout = () =>
    logoutRequest()
      .unwrap()
      .then(() => dispatch(logOutCheckout()));

  const logOutDeletedUser = () => {
    dispatch(logOutAction());
    router.push('/');
  };

  /**
   * Call this function on pages you wish to restrict access to only
   * authenticated users. It will capture the page the user is currently on
   * and redirect to login.
   *
   * On successful login, it will redirect back to the original page
   */
  useEffect(() => {
    if (authInterval) {
      clearTimeout(authInterval);
    }

    authInterval = setTimeout(() => {
      if (!token && authRestrictedPage) {
        dispatch(setIntended(router.asPath));
        isNewLogin ? router.push(LOGIN_ROUTE_NEW) : router.push(LOGIN_ROUTE);
      }
    }, 100);

    return () => clearTimeout(authInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    /**
     * The login handler. If you pass an callback, that function will be run
     * in most cases you wish this to be null. If a callback function is not
     * provided, it will automatically redirect the user back to the page he
     * originally came from.
     *
     * @param   {object}  credentials  containing email and password
     * @param   {func}  callback       an optional callback function on success
     *
     * @return  {Promise}              The promise for the auth request
     */
    login: (
      credentials: LoginRequest,
      callback: null | Function = null,
      noRedirect?: boolean
    ) =>
      login(credentials)
        .unwrap()
        .then((response) => {
          if (noRedirect) return;

          // if we have a provided callback,
          // run that, and return early
          if (typeof callback === 'function') {
            return callback(response);
          }

          handleIsLoggedIn();
        }),
    // will be true on invalid login or request error
    isError,

    // when login request is in-flight
    isLoading: isLoadingUser || isLoggingIn,

    // is authenticated
    isAuthenticated,

    // handle when user is logged in
    handleIsLoggedIn,

    // log user out
    logOut,

    // log out deleted user
    logOutDeletedUser,

    // log out from checkout
    logOutFromCheckout,

    // user (if authenticated)
    user: user || {},

    // log out in checkout
    switchUserCheckout,

    // refetch user
    refetch,
  };
};

export default useAuth;
